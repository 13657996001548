/*
.fc-event, .event-title {
    padding: 0 1px;
    white-space: normal;
}
*/

.fc .fc-timegrid-slot {
    height: 2em;
}
